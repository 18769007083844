import { msalInstance } from "../index";
import { FIRST_LOGIN_TIMESTAMP_KEY } from "./Constants";

export const getToken = async (scopes:any) => {
    
    if (!navigator.onLine) {
        console.warn("Offline detected. Skipping token renewal.");
        // Optionally notify the user that they're offline
        return null;
      }

    const account = msalInstance?.getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    // Check if it's the first login and save the timestamp
    const firstLoginTimestamp = localStorage.getItem(FIRST_LOGIN_TIMESTAMP_KEY);
    if (!firstLoginTimestamp) {
        localStorage.setItem(FIRST_LOGIN_TIMESTAMP_KEY, new Date().toISOString());
    }

    const response = await msalInstance.acquireTokenSilent({
        account: account,
        scopes: scopes
    });

    return response.accessToken;
}