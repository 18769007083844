import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { LogLevel } from '@grafana/faro-web-sdk';
import { RumInit } from '@maersk-global/telemetry-web-sdk';
import { ReactIntegration, ReactRouterVersion } from '@grafana/faro-react';
import env from 'react-dotenv';
import { Workbox } from 'workbox-window';
import { db } from './utils/PWA/AppDatabase';
import useNetworkStatus from './utils/PWA/useNetworkStatus';
let { REACT_APP_ENV: AppEnv, REACT_APP_API_KEY: ApiKey, REACT_APP_NAME: AppName } = env;

export const msalInstance = new PublicClientApplication(msalConfig);

const RootComponent = () => {
  const isOnline = useNetworkStatus();

  if (isOnline) {
    try {
      if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }

      msalInstance.enableAccountStorageEvents();

      msalInstance.addEventCallback((event: any) => {
        try {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
          }
        } catch (error) {
          console.error('Error handling MSAL event callback:', error);
        }
      });
    } catch (error) {
      console.error('Error initializing MSAL instance:', error);
    }
  }

  return (
    <BrowserRouter>
      <App msalInstance={isOnline ? msalInstance : null} />
    </BrowserRouter>
  );
};

window.addEventListener('DOMContentLoaded', function (e) {
  try {
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<RootComponent />);
  } catch (error) {
    console.error('Error rendering RootComponent:', error);
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// Register service worker if available
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js'); // Path to the generated service worker

  wb.addEventListener('activated', (event) => {
    console.log('Service Worker activated: ', event);
  });

  wb.register().then(() => {
    try {
      wb.update(); // Force the update if necessary

      if ('Notification' in window && navigator.serviceWorker) {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
            } else if (permission === 'denied') {
              console.log('Notification permission denied.');
            } else {
              console.log('Notification permission dismissed.');
            }
          })
          .catch((error) => {
            console.error('Error requesting notification permission:', error);
          });
      } else {
        console.log('Notifications are not supported in this browser.');
      }

      console.log('Service Worker registered successfully');
    } catch (error) {
      console.error('Error during service worker registration:', error);
    }
  }).catch((error) => {
    console.error('Error registering service worker:', error);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// const faro = RumInit({
//   app: {
//     name:AppName as string,
//     version: "1.0.0",
//     environment: AppEnv as string,
//   },
//   apiKey:ApiKey as string,
//    instrumentations:{
//     interactions: true,
//     xhr: true,
//     console:{
//       disabledLevels:[LogLevel.TRACE,LogLevel.INFO]
//     },
//   },
//   performanceInstrument:{
//     ignoreEvents:['msal.*'] // To ignore certain events pass a list of exact events names or regex
//   },
//   isolate: true,
//   spanLimitInTrace: 3,
//   useSendBeacon: true,
//   transportBufferSize: 5,
//   rateLimitBackoffMs: 5000,
//   propagateTraceHeaderCorsUrls: [new RegExp('.*api.*')],
//   debug: false, // false: Prevent showing log,error,etc captured in browser console
//   traceLog: false, // Sends Traces to Loki ( For easy querying of traces)

//    batching:{
//     // enable/disable batch sending of events
//     enabled: true, // default
//     // The interval in which to send event batches
//     sendTimeout:1000, // default
//     // The size of the signal buffer before the batch is sent (overrides interval)
//     itemLimit: 4
//   }
// });

// faro.instrumentations.add(
//   new ReactIntegration({
//   // If the version of React Router is V6
//   router: {
//     version: ReactRouterVersion.V6,
//     dependencies: {
//       createRoutesFromChildren,
//       matchRoutes,
//       Routes,
//       useLocation,
//       useNavigationType
//     }
//   }
// })
// );