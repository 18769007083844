import React, { useEffect, useState } from 'react';
import useNetworkStatus from './useNetworkStatus';

const NetworkStatus = () => {

  const isOnline = useNetworkStatus();

  return (
    <>
      {!isOnline && <div
        style={{
          padding: '10px',
          backgroundColor:  'gray',
          color: 'white',
          textAlign: 'center',
          //position: 'fixed',
          width: '100%',
          //top: 0,
          zIndex: 1000,
        }}
      >
        <p>You are offline. Data is loaded from cache. Some functionalities may not be available.</p>
      </div>}
    </>
  );
};

export default NetworkStatus;


