import { useState, useEffect } from 'react';

// Custom hook to detect network changes
const useNetworkStatus = (checkUrl: string = '/favicon.ico', checkInterval: number = 7000) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const checkConnectivity = async () => {
      try {
        // Ping a known URL
        const response = await fetch(checkUrl, { method: 'HEAD', cache: 'no-store' });
        if (response.status===200) {
          setIsOnline(true);
          return;
        }
      } catch (error) {
        setIsOnline(false);
      }
      
    };

    // Initial check
    checkConnectivity();

    // Periodic checks
    intervalId = setInterval(checkConnectivity, checkInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkUrl, checkInterval]);

  return isOnline;
};


export default useNetworkStatus;