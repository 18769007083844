import { LoggedInUserModel } from "../models/UserLoginModel";
import { msalInstance } from "../index";
import { getToken } from "./token";
import { protectedResources } from "../authConfig";
import { jwtDecode } from "jwt-decode";

export const getUserDetails = async (): Promise<LoggedInUserModel> => {
    const loggedInUser = new LoggedInUserModel();
    if (msalInstance) {
        const account = msalInstance?.getActiveAccount();
        const token = await getToken(protectedResources.vass.scopes.read);

        if (token) {
            const decodedToken: any = jwtDecode(token);

            if (account) {
                if (account) {
                    loggedInUser.name = account.name;
                    loggedInUser.email = account.username;
                    loggedInUser.isLoggedIn = true;
                    if (decodedToken) {
                        loggedInUser.roles = decodedToken['roles'] ? decodedToken['roles'] : account.idTokenClaims?.roles;
                    }
                }
            }
        }        
    }

return loggedInUser;
}
