import { HttpClientHandler } from '../httpHandler/HttpClientHandler';
import { post, get, deleteAPI } from '../utils/ajax';
import { SaveFilterResponse, VesselDetailModel, VesseListApiResponseModel } from '../pages/Dashboard/models';
import env from "react-dotenv";
import { getToken } from '../utils/token';
import { protectedResources } from "../authConfig";

const { REACT_APP_API_URL: BASE_URL } = env;
let abortController: AbortController;
export const VesselListService = async (
  pageNumber: number,
  itemsPerPage: number,
): Promise<VesseListApiResponseModel> => {
  try {
    const vesselDetailSearchModel = new VesselDetailModel();
    vesselDetailSearchModel.ItemsPerPage = itemsPerPage;
    vesselDetailSearchModel.Page = pageNumber;
    vesselDetailSearchModel.LastInspectionDateStart = '0001-01-01T00:00:00Z';
    vesselDetailSearchModel.LastInspectionDateEnd = '0001-01-01T00:00:00Z';
    vesselDetailSearchModel.SortColumn = 1;
    vesselDetailSearchModel.DeclinedAutoselectionForSurveyDate = '0001-01-01T00:00:00Z';
    
    const accessToken = await getToken(protectedResources.vass.scopes.read);  
    const bearer = `Bearer ${accessToken}`;

    let response = await HttpClientHandler.post<VesseListApiResponseModel>(
      '/Vessel/GetVesselData',
      vesselDetailSearchModel,
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'accept': 'application/json',
          'Authorization': bearer
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log('error is ', error);
    const errorModel = new VesseListApiResponseModel();
    errorModel.Errors = error;
    return errorModel;
  }
};

export const getOwnVesselList = async (filterId: number) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return get(
    `${BASE_URL}OwnVessel/GetOwnVesselData?filterId=${filterId}`,
    {}
  ).catch((err) => {
    // Do not throw errors when request has been aborted
    if (!(err instanceof DOMException)) {
      throw Error(err);
    }
  });
}

export const saveFilterRecord = async (filterCondition: any, filtername: string, filterTypeId: number ): Promise<SaveFilterResponse> => {
  try {
    const response = await post(
      `${BASE_URL}OwnVessel/SaveFilter?filterName=${filtername}&filterTypeId=${filterTypeId}`,
      { filterCondition },
    );
    if (response.status === 200) {
      return new SaveFilterResponse(true, 'Filter Saved Successfully', response.status);
    }
    else {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Failed to save filter');
    }
  } catch (error: any) {
    console.error('Error in saveFilter:', error);
    // Check if the error message contains "Filter name already exists"
    const errorMessage = error.message.includes('Filter name') ? 'Filter name already exists' : 'Some error occurred while saving the filter';

    // Return an error response
    return new SaveFilterResponse(false, errorMessage, 500);
  }
}

export const updateFilterRecord = async (filterId: number, filterCondition: any): Promise<SaveFilterResponse> => {
  try {
    const response = await post(
      `${BASE_URL}OwnVessel/UpdateFilter?filterId=${filterId}`,
      { filterCondition },
    );
    if (response.status === 200) {
      return new SaveFilterResponse(true, 'Filter Updated Successfully', response.status);
    }
    else {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Failed to update filter');
    }
  } catch (error: any) {
    console.error('Error in updateFilter:', error);
    // Check if the error message contains "Filter name already exists"
    const errorMessage = error.message.includes('Filter name') ? 'Filter name already exists' : 'Some error occurred while updating the filter';

    // Return an error response
    return new SaveFilterResponse(false, errorMessage, 500);
  }
}

export const deleteFilterRecord = async (filterId: number): Promise<boolean> => {
  try {
    const response = await deleteAPI(`${BASE_URL}OwnVessel/DeleteFilter/${filterId}`);
    if (response.status === 200) {
      return true;
    }
    else {
      throw new Error('Failed to delete filter');
    }
  } catch (error) {
    console.error('Error in deleteFilter:', error);
    return false;
  }
}


export const getUserFilterList = async (filterTypeId: number) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  try {
    const response = await get(`${BASE_URL}OwnVessel/GetFiltersData/${filterTypeId}`);
    return response;
  } catch (err) {
    if (!(err instanceof DOMException)) {
      throw Error(String(err));
    }
  }
};
